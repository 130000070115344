
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";

interface schoolDB {
  name?: string;
  adress: string;
  createdAt: Date;
  dbName: string;
  headMaster: string;
  legalName: string;
  logo: string;
  phone: number;
  type: string;
  updatedAt: Date;
  _id: string;
  base_url?: string;
}

export default defineComponent({
  name: "password-forgot",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const requested = ref(false);

    const submitButton = ref<HTMLButtonElement | null>(null);

    const options = ref<schoolDB[]>([]);
    const selectedDB = ref<schoolDB | undefined>(undefined);
    const selectedDBName = ref<string>("default");

    ApiService.setHeaderBuilding("Root");
    ApiService.post(process.env.VUE_APP_API_URL + "/api/web/buildings/filter", {
      query: { active: true, "access.edLearning.active": true },
    })
      .then(({ data }) => {
        data.forEach((option: schoolDB) => {
          let urlSuffix = option.base_url
            ? option.base_url + "/"
            : process.env.VUE_APP_API_URL + "/";
          console.log(urlSuffix);
          if (option.logo)
            option.logo = urlSuffix + option.logo.replaceAll("\\", "/");
          else option.logo = "media/logos/edtrust.png";
        });
        options.value = data;
      })
      .catch((e) => console.log(e));

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string()
        .required(t("editProfile.requiredEmail"))
        .email(t("editProfile.invalidEmail")),
      schoolName: Yup.object()
        .required(t("editProfile.requiredSchool"))
        .nullable(),
    });

    //Form submit function
    const onSubmitForgotPassword = (values) => {
      // Activate loading indicator

      // eslint-disable-next-line
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // eslint-disable-next-line
      ApiService.setHeaderBuilding(selectedDB.value!.dbName);

      delete values.schoolName;

      // Send login request
      store
        .dispatch(Actions.FORGOT_PASSWORD, {
          ...values,
          url: window.location.origin,
        })
        .then(() => {
          requested.value = true;

          // eslint-disable-next-line
          submitButton.value?.removeAttribute("data-kt-indicator");

          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        })
        .catch((e) => {
          console.error(e);
          // Alert then login failed
          Swal.fire({
            text: t("resetpassword.emailNotExist"),
            icon: "error",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });

          // eslint-disable-next-line
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        });
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
      t,
      requested,
      options,
      selectedDB,
      selectedDBName,
    };
  },
});
